import React from 'react';
import styled from 'styled-components';

const FullScreen = styled.div`
  height: 100vh;
  width: 100%;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LoadingText = styled.p`
  color: grey;
  font-size: 14px;
  text-align: center;
`;

const FullScreenLoading = ({ text }) => (
  <FullScreen>
    <LoadingText>{text}</LoadingText>
  </FullScreen>
);

export default FullScreenLoading;
