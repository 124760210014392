import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import styled from 'styled-components';
import SweepsMore from './Pages/ENG/SweepsMore';
import FullScreenLoading from './Components/Commons/FullScreenLoading';

const EngRoutes = lazy(() => import('./Components/Commons/EngRoutes'));
const SpaRoutes = lazy(() => import('./Components/Commons/SpaRoutes'));
const AffRoutes = lazy(() => import('./Components/Commons/AffRoutes'));

const AppDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
  height: 100%;
  min-height: 100%;
`;

function App() {
  return (
    <Router>
      <AppDiv>
        <Route exact path='/land/sweeps-more' component={SweepsMore} />
        <Route path='/land'>
          <Suspense fallback={<FullScreenLoading text='Loading English Landing Pages...' />}>
            <EngRoutes />
          </Suspense>
        </Route>
        <Route path='/es/land'>
          <Suspense fallback={<FullScreenLoading text='Loading Spanish Landing Pages...' />}>
            <SpaRoutes />
          </Suspense>
        </Route>
        <Route path='/aff'>
          <Suspense fallback={<FullScreenLoading text='Loading Affiliates Landing Pages...' />}>
            <AffRoutes />
          </Suspense>
        </Route>
      </AppDiv>
    </Router>
  );
}

export default App;
